export const FETCH_START = "FETCH_START"
export const FETCH_END = "FETCH_END"
export const FORECAST_LEVEL_DATA_SUCCESS = "FORECAST_LEVEL_DATA_SUCCESS"
export const FORECAST_LEVEL_BY_ID = "FORECAST_LEVEL_BY_ID"
export const FORECAST_LEVEL_DROPDWON_SUCCESS = "FORECAST_LEVEL_DROPDWON_SUCCESS"
export const SALES_FORECAST_FORMAT_LIST_SUCCESS = "SALES_FORECAST_FORMAT_LIST_SUCCESS"
export const DYNAMIC_QUERY_SALES_FORECAST_DETAILS_SUCCESS = "DYNAMIC_QUERY_SALES_FORECAST_DETAILS_SUCCESS"
export const SALES_FORECAST_QUERY_SINGLE_SUCCESS = "SALES_FORECAST_QUERY_SINGLE_SUCCESS"
export const SALES_FORECAST_QUERY_FIELDS_SUCCESS = "SALES_FORECAST_QUERY_FIELDS_SUCCESS"
export const DYNAMIC_QUERY_FORECAST_FIELDS_SUCCESS = "DYNAMIC_QUERY_FORECAST_FIELDS_SUCCESS"
export const SALES_FORECAST_UPLOAD_SUCCESS = "SALES_FORECAST_UPLOAD_SUCCESS"
export const DISPLAY_FORECAST_SUCCESS = "DISPLAY_FORECAST_SUCCESS"
export const SALES_FORECAST_DATA_SUCCESS = "SALES_FORECAST_DATA_SUCCESS"