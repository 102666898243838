import {
  ATTRIBUTE_LIST_SUCCESS,
  ATTRIBUTE_LIST_FAIL,
  FETCH_START,
  FETCH_END,
  EDIT_ATTRIBUTES_SUCCESS,
  ATTRIBUTE_NAME_SUCCESS,
  DROPDOWN_START,
  DROPDOWN_END,
  DROPDOWN_DATA_SUCCESS,
  DROPDOWN_DATA_VALIDATION_SUCCESS,
  DROPDOWN_MASTER_DATA_SUCCESS,
  CREATE_ATTRIBUTE_HIERARCHY_SUCCESS,
  ATTRIBUTE_LIST_SINGLE_DATA_SUCCESS,
} from "./ActionTypes";
import axios from "../../../axios.conf";
import { toast } from "react-toastify";
import { ServerError } from "../../../library/constants";
import { history } from "../../../components/Helpers";
import secureLocalStorage from "react-secure-storage";

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};

export const saveEditAttributeData = (data) => {
  return {
    type: EDIT_ATTRIBUTES_SUCCESS,
    data,
  };
};

export const getAttributeListDataSuccess = (data) => {
  return {
    type: ATTRIBUTE_LIST_SUCCESS,
    data,
  };
};

export const getAttributeListDataFailed = (error) => {
  return {
    type: ATTRIBUTE_LIST_FAIL,
    error,
  };
};
export const getAttributeNameDataSuccess = (data) => {
  return {
    type: ATTRIBUTE_NAME_SUCCESS,
    data,
  };
};
export const getDropdownValidationSuccess = (data) => {
  return {
    type: DROPDOWN_DATA_VALIDATION_SUCCESS,
    data,
  };
};
export const getDropdownDataSuccess = (data) => {
  return {
    type: DROPDOWN_DATA_SUCCESS,
    data,
  };
};
export const getDropdownDataMasterDataSuccess = (data) => {
  return {
    type: DROPDOWN_MASTER_DATA_SUCCESS,
    data,
  };
};
export const fetchDropdownStart = () => {
  return {
    type: DROPDOWN_START,
  };
};

export const fetchDropdownEnd = () => {
  return {
    type: DROPDOWN_END,
  };
};

export const getAttributeListData = (data, page) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getAttributeListDataApi(data, page)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getAttributeListDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(getAttributeListDataFailed(error));
        //toast.error(ServerError);
      });
  };
};

const getAttributeListDataApi = (data, page) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (page === "hierarchy") {
    result = axios.get("eligibility-attrib-multiple", { headers: headers });
    return result;
  }
  else {
    result = axios.get("eligibility-attrib", { headers: headers });
    return result;
  }

};

export const createAttributeData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createAttributeDataApi({
      attribute_name: data.attribute_name,
      attribute_value: data.attribute_value,
      attribute_desc: data.attribute_desc,
    })
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 200) {
          history.push("/attributes");
          toast.success("Successfully created");
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const createAttributeDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("eligibility-attrib", data, { headers: headers });
  return result;
};

export const updateAttributeData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateAttributeDataApi(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 200) {
          toast.success("Successfully updated");
          history.push("/attributes");
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const updateAttributeDataApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.put("eligibility-attrib/" + id, data, { headers: headers });
  return result;
};

export const deleteAttributeData = (id, page) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteAttributeDataApi(id, page)
      .then((response) => {
        dispatch(fetchEnd());
        if (page === "hierarchy") {
          dispatch(getAttributeListData(null, "hierarchy"));
          toast.success("Successfully deleted");
        }
        else {
          dispatch(getAttributeListData());
          toast.success("Successfully deleted");
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const deleteAttributeDataApi = (id, page) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (page === "hierarchy") {
    result = axios.delete("eligibility-hierarchy-attrib/" + id, { headers: headers });
    return result;
  }
  else {
    result = axios.delete("eligibility-attrib/" + id, { headers: headers });
    return result;
  }
};

// //upload File
export const uploadAttributeFile = (file, page) => {
  return (dispatch) => {
    uploadAttributeFileApi(file, page)
      .then((response) => {
        if (response.status === 200) {
          // window.location.reload();
          if (page === "hierarchy") {
            dispatch(getAttributeListData(null, "hierarchy"));
            toast.success("File has been successfully uploaded.");
          }
          else {
            dispatch(getAttributeListData());
            toast.success("File has been successfully uploaded.");
          }
        } else {
          toast.error(ServerError);
        }
      })
      .catch((error) => {
        // console.log(error.response.data.errors);
        const errorMessages = Object.values(error.response.data.errors);
        errorMessages.forEach((errorMessage) => {
          toast.error(errorMessage.trim());
        });
        // const errorMessages = error.response.data.errors.split(';');
        // errorMessages.forEach((errorMessage) => {
        //   toast.error(errorMessage.trim());
        // });
      });
  };
};

const uploadAttributeFileApi = (file, page) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
    "Content-Type": "multipart/form-data;",
  };
  var result;
  if (page === "hierarchy") {
    result = axios.post("upload-hierarchy-attrib", file, { headers: headers });
    return result;
  } else {
    result = axios.post("eligibility-attrib-upload", file, { headers: headers });
    return result;
  }

};

export const getAttributeNameList = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getAttributeNameApi()
      .then((response) => {
        dispatch(getAttributeNameDataSuccess(response.data.response));
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getAttributeNameApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("/attribute-names", { headers: headers });
  return result;
};

export const uploadEDIFile = (file) => {
  return (dispatch) => {
    uploadEDIFileAPI(file)
      .then((response) => {
        if (response.status === 200) {
          // window.location.reload();
          toast.success("File has been successfully uploaded.");
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        //toast.error(ServerError);
      });
  };
};

const uploadEDIFileAPI = (file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
    "Content-Type": "multipart/form-data;",
  };
  var result;
  result = axios.post("incoming-claim-edi", file, { headers: headers });
  return result;
};
//searched dropdown
export const getDropdownList = (table, data, type) => {
  return (dispatch) => {
    dispatch(fetchDropdownStart());
    getDropdownApi(table, data)
      .then((response) => {
        if (type === "Master Data") {
          dispatch(getDropdownDataMasterDataSuccess(response.data.response));
        }
        else {
          dispatch(getDropdownDataSuccess(response.data.response));
        }
        dispatch(fetchDropdownEnd());
      })
      .catch((error) => {
        dispatch(fetchDropdownEnd());
      });
  };
};

const getDropdownApi = (table, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("dropdown-search/" + table, data, { headers: headers });
  return result;
};

// Search DropDown Validation

export const getDropdownValidation = (table, data) => {
  return (dispatch) => {
    // dispatch(fetchDropdownStart());
    getDropdownValidationApi(table, data)
      .then((response) => {
        sessionStorage.setItem('dropdownvalidation', JSON.stringify(response.data.response))
        dispatch(getDropdownValidationSuccess(response.data.response));
        // dispatch(fetchDropdownEnd());
      })
      .catch((error) => {
        // dispatch(fetchDropdownEnd());
      });
  };
};

const getDropdownValidationApi = (table, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("dropdown-multi-search/" + table, data, { headers: headers });
  return result;
};

//Attributes Hierarchy 

// Add Attributes Hierarchy

export const createAttributeHierarchyData = (data, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createAttributeHierarchyDataAPi(data, id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.response.length > 0) {
          dispatch(fetchEnd());
          dispatch(createAttributeHierarchyDataSuccess(response.data.response));
          history.push("/attributes-hierarchy");
        } else {
          toast.warning("No records found.");
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createAttributeHierarchyDataAPi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  if (id) {
    var result = axios.put("eligibility-hierarchy-attrib/" + id, data, { headers: headers });
    return result;
  }
  else {
    var result = axios.post("eligibility-attrib-multiple", data, { headers: headers });
    return result;
  }

};
export const createAttributeHierarchyDataSuccess = (data) => {
  return {
    type: CREATE_ATTRIBUTE_HIERARCHY_SUCCESS,
    data,
  };
};

// Get Single Attributes Hierarchy

export const getAttributeListSingleData = (id) => {
  return (dispatch) => {
    getAttributeListSingleDataApi(id)
      .then((response) => {
        dispatch(getAttributeListSingleDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};
const getAttributeListSingleDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("eligibility-attrib-multiple/" + id, { headers: headers });
  return result;
};

export const getAttributeListSingleDataSuccess = (data) => {
  return {
    type: ATTRIBUTE_LIST_SINGLE_DATA_SUCCESS,
    data,
  };
};
