import {
  COLLABORATION_FILTER_SUCCESS,
  COLLABORATION_DESI_ALL,
  COLLABORATION_DESI_SINGLE,
  FETCH_START,
  FETCH_END,
  COLLABORATION_DESI_LIST_SUCCESS,
} from "./ActionTypes";
import axios from "../../../axios.conf";
import { toast } from "react-toastify";
import { ServerError } from "../../../library/constants";
import { history } from "../../../components/Helpers";
import secureLocalStorage from "react-secure-storage";

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};
export const getCollaborationDesignerData = (pagination, limit, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getCollaborationDesignerAPI(pagination, limit, id)
      .then((response) => {
        dispatch(getCollaborationDesignerSuccess(response.data.response));
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getCollaborationDesignerAPI = (pagination, limit, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (id) {
    result = axios.get("collaborations-access/" + id, { headers: headers });
  } else {
    result = axios.get("collaborations-access?page=" + pagination + "&limit=" + limit, { headers: headers });
  }
  return result;
};

export const getCollaborationDesignerSuccess = (data) => {
  return {
    type: COLLABORATION_DESI_ALL,
    data,
  };
};

export const deleteCollaborationDesigner = (id) => {
  return (dispatch) => {
    deleteCollaborationDesignerApi(id)
      .then((response) => {
        dispatch(getCollaborationDesignerData());
        toast.success(response.data.response);
      })
      .catch((error) => { });
  };
};

const deleteCollaborationDesignerApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.delete("collaborations-access/" + id, { headers: headers });
  return result;
};

export const addCollaborationDesigner = (data, edit, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addCollaborationDesignerAPI(data, edit, id)
      .then((response) => {
        dispatch(fetchEnd());
        history.push("/collaboration-designer");
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const addCollaborationDesignerAPI = (data, edit, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  for (var propName in data) {
    if (
      data[propName] === "" ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data[propName] === "1970-01-01T00:00:00.000Z" ||
      data[propName] === ","
    ) {
      delete data[propName];
    }
  }
  if (edit === "edit")
    result = axios.put("/collaborations-access/" + id, data, {
      headers: headers,
    });
  else
    result = axios.post("/collaborations-access", data, { headers: headers });
  return result;
};
