export const DOCUMENT_DATA_BY_ID = 'DOCUMENT_DATA_BY_ID';
export const DOCUMENT_DATA = 'DOCUMENT_DATA';
export const DOCUMENT_LEVEL_BY_ID = 'DOCUMENT_LEVEL_BY_ID';
export const DOCUMENT_LEVEL_DATA = 'DOCUMENT_LEVEL_DATA';
export const DISTINCT_DOCUMENT_LEVEL_DATA='DISTINCT_DOCUMENT_LEVEL_DATA';
export const DOCUMENT_STATUS="DOCUMENT_STATUS";
export const DOCUMENT_HISTORY="DOCUMENT_HISTORY";
export const FETCH_START = 'FETCH_START';
export const FETCH_END = 'FETCH_END';
export const SEARCH_DOCUMENT_SUCESS = 'SEARCH_DOCUMENT_SUCESS'

