import {
    CHAT_GPT_NORMAL,
    COMMUNITY_CHAT_MAIN,
    GET_ALL_COMMUNITY_CHAT,
} from '../../actions/ChatGPTAI/ActionTypes';

const initialState = {
    chatgptnormalData: [],
    communityChatData:[],
    allCommunityChat:[],
};

const ChatGptReducer = (state = initialState, action) => {
    switch (action.type) {
        case  CHAT_GPT_NORMAL:
            return {
                ...state,
                chatgptnormalData: action.data,
            };  
        case COMMUNITY_CHAT_MAIN: 
            return {
                ...state,
                communityChatData:action.data
            }
        case GET_ALL_COMMUNITY_CHAT:
            return {
                ...state,
                allCommunityChat:action.data
            }
        default:
            return state;
    }
};

export default ChatGptReducer;