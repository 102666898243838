export const ATTRIBUTE_LIST_SUCCESS = 'ATTRIBUTE_LIST_SUCCESS';
export const ATTRIBUTE_LIST_FAIL = 'ATTRIBUTE_LIST_FAIL';
export const EDIT_ATTRIBUTES_SUCCESS = 'EDIT_ATTRIBUTES_SUCCESS';
export const EDIT_ATTRIBUTES_FAIL = 'EDIT_ATTRIBUTES_FAIL';
export const FETCH_START = 'FETCH_START';
export const FETCH_END = 'FETCH_END';
export const ATTRIBUTE_NAME_SUCCESS = 'ATTRIBUTE_NAME_SUCCESS';
export const DROPDOWN_START = "DROPDOWN_START";
export const DROPDOWN_END = "DROPDOWN_END";
export const DROPDOWN_DATA_SUCCESS = "DROPDOWN_DATA_SUCCESS";
export const DROPDOWN_DATA_VALIDATION_SUCCESS = "DROPDOWN_DATA_VALIDATION_SUCCESS";
export const DROPDOWN_MASTER_DATA_SUCCESS = "DROPDOWN_MASTER_DATA_SUCCESS";
export const CREATE_ATTRIBUTE_HIERARCHY_SUCCESS = "CREATE_ATTRIBUTE_HIERARCHY_SUCCESS";
export const ATTRIBUTE_LIST_SINGLE_DATA_SUCCESS = "ATTRIBUTE_LIST_SINGLE_DATA_SUCCESS";