import {
    EXTERNAL_FORMULA_SUCCESS,
    EXTERNAL_FORMULA_VIEW_DETAIL_SUCCESS,
    EXTERNAL_FORMULA_SINGLE_SUCCESS,
    EXTERNAL_FORMULA_FORMAT_SUCCESS,
    EXTERNAL_FORMULA_FORMAT_BY_ID_SUCCESS,
    EXTERNAL_FORMULA_FORMAT_FIELDS_SUCCESS,
    FETCH_START,
    FETCH_END,
} from '../../actions/ExternalFormulaResults/ActionTypes';

const initialState = {
    loading: false,
    externalFormulaData: [],
    externalFormulaViewDetail: [],
    externalFormulaSingleData: [],
    externalFormulaFormatData: [],
    externalFormulaFormatFieldData: [],
    externalFormulaSingleFormatData: [],
};

const ExternalFormulaResultsReducer = (state = initialState, action) => {
    switch (action.type) {
        case EXTERNAL_FORMULA_SUCCESS:
            return {
                ...state,
                externalFormulaData: action.data,

            };

        case EXTERNAL_FORMULA_VIEW_DETAIL_SUCCESS:
            return {
                ...state,
                externalFormulaViewDetail: action.data,

            };

        case FETCH_START:
            return {
                ...state,
                loading: true
            }
        case FETCH_END:
            return {
                ...state,
                loading: false
            }
        case EXTERNAL_FORMULA_SINGLE_SUCCESS:
            return {
                ...state,
                externalFormulaSingleData: action.data,
            }
        case EXTERNAL_FORMULA_FORMAT_SUCCESS:
            return {
                ...state,
                externalFormulaFormatData: action.data,
            }
        case EXTERNAL_FORMULA_FORMAT_BY_ID_SUCCESS:
            return {
                ...state,
                externalFormulaSingleFormatData: action.data,
            }
        case EXTERNAL_FORMULA_FORMAT_FIELDS_SUCCESS:
            return {
                ...state,
                externalFormulaFormatFieldData: action.data,
            }
        default:
            return state;
    }
};

export default ExternalFormulaResultsReducer;