import {
    PROFILE_DETAILS_FAIL,
    PROFILE_DETAILS_SUCCESS,
    FETCH_START,
    FETCH_END,
    EDIT_PROFILE_SUCCESS,
    EDIT_PROFILE_FAIL,
    CURRENT_APPLICATION
} from '../../actions/Profile/ActionTypes';

const initialState = {
    loading: false,
    currentApp: '',
    profileData: []
};

const ProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case PROFILE_DETAILS_SUCCESS:
            return {
                ...state,
                profileData: action.data,
                loading: false
            };
        case PROFILE_DETAILS_FAIL:
            return {
                ...state,
                loading: false
            };
        case EDIT_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case EDIT_PROFILE_FAIL:
            return {
                ...state,
                loading: false
            };
        case FETCH_START:
            return {
                loading: true
            }
        case FETCH_END:
            return {
                loading: false
            }
        case CURRENT_APPLICATION:
            return {
                ...state,
                currentApp: action.data,
            };
        default:
            return state;
    }
};

export default ProfileReducer;