import {
    FORMULA_LIST_SUCCESS,
    FORMULA_DETAIL_SUCCESS
} from '../../../actions/FormulaLab/FormulaLab/ActionTypes';

const initialState = {
    formulaListData: {},
    formulaDetailsData: {}
};

const FormulaLabReducer = (state = initialState, action) => {
    switch (action.type) {
        case FORMULA_LIST_SUCCESS:
            return {
                ...state,
                formulaListData: action.data,
            };
        case FORMULA_DETAIL_SUCCESS:
            return {
                ...state,
                formulaDetailsData: action.data,
            };
        default:
            return state;
    }
};

export default FormulaLabReducer;