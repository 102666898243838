import {
    ANAMOLY_LIST_SUCCESS,
    ANAMOLY_DETAIL_SUCCESS,
    ANAMOLY_CONFIG_SINGLE,
} from '../../actions/AnamolyConfig/ActionTypes';

const initialState = {
    anamolyListData: {},
    anamolyDetailsData: {}
};

const AnamolyReducer = (state = initialState, action) => {
    switch (action.type) {
        case ANAMOLY_LIST_SUCCESS:
            return {
                ...state,
                anamolyListData: action.data,
            };
        case ANAMOLY_DETAIL_SUCCESS:
            return {
                ...state,
                anamolyDetailsData: action.data,
            };
        case ANAMOLY_CONFIG_SINGLE:
            return{
                ...state,
                anamolySingleData: action.data,
            }    
        default:
            return state;
    }
};

export default AnamolyReducer;