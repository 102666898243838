export const PERFORMANCE_METRIC_SUCCESS = "PERFORMANCE_METRIC_SUCCESS"
export const  PERFORMANCE_METRIC_SINGLE_SUCCESS =" PERFORMANCE_METRIC_SINGLE_SUCCESS"
export const PERFORMANCE_METRIC_FORMAT_SUCCESS="PERFORMANCE_METRIC_FORMAT_SUCCESS"
export const PERFORMANCE_METRIC_FORMAT_BY_ID_SUCCESS = "PERFORMANCE_METRIC_FORMAT_BY_ID_SUCCESS"
export const PERFORMANCE_METRIC_FORMAT_FIELDS_SUCCESS="PERFORMANCE_METRIC_FORMAT_FIELDS_SUCCESS"
export const PERFORMANCE_WEIGHT_SUCCESS = "PERFORMANCE_WEIGHT_SUCCESS"
export const PERFORMANCE_WEIGHT_SINGLE_SUCCESS =" PERFORMANCE_WEIGHT_SINGLE_SUCCESS"
export const PERFORMANCE_WEIGHT_FORMAT_SUCCESS="PERFORMANCE_WEIGHT_FORMAT_SUCCESS"
export const PERFORMANCE_WEIGHT_FORMAT_BY_ID_SUCCESS = "PERFORMANCE_WEIGHT_FORMAT_BY_ID_SUCCESS"
export const PERFORMANCE_WEIGHT_FORMAT_FIELDS_SUCCESS="PERFORMANCE_WEIGHT_FORMAT_FIELDS_SUCCESS"
export const FETCH_START="FETCH_START"
export const FETCH_END ="FETCH_END"