import {
    PERFORMANCE_METRIC_SUCCESS,
    PERFORMANCE_METRIC_SINGLE_SUCCESS,
    PERFORMANCE_METRIC_FORMAT_SUCCESS,
    PERFORMANCE_METRIC_FORMAT_BY_ID_SUCCESS,
    PERFORMANCE_METRIC_FORMAT_FIELDS_SUCCESS,
    PERFORMANCE_WEIGHT_SUCCESS,
    PERFORMANCE_WEIGHT_SINGLE_SUCCESS,
    PERFORMANCE_WEIGHT_FORMAT_SUCCESS,
    PERFORMANCE_WEIGHT_FORMAT_BY_ID_SUCCESS,
    PERFORMANCE_WEIGHT_FORMAT_FIELDS_SUCCESS,
    FETCH_START,
    FETCH_END
} from '../../actions/PerformanceMetric/ActionTypes';

const initialState = {
    loading: false,
    performanceMetricData: [],
    performanceMetricSingleData: [],
    performanceMetricFormatData: [],
    performanceMetricFormatFieldData: [],
    performanceMetricSingleFormatData: [],
    performanceWeightData: [],
    performanceWeightSingleData: [],
    performanceWeightFormatData: [],
    performanceWeightFormatFieldData: [],
    performanceWeightSingleFormatData: []
};

const PerformanceMetricReducer = (state = initialState, action) => {
    switch (action.type) {
        case PERFORMANCE_METRIC_SUCCESS:
            return {
                ...state,
                performanceMetricData: action.data,

            }

        case FETCH_START:
            return {
                ...state,
                loading: true
            }
        case FETCH_END:
            return {
                ...state,
                loading: false
            }
        case PERFORMANCE_METRIC_SINGLE_SUCCESS:
            return {
                ...state,
                performanceMetricSingleData: action.data,
            }
        case PERFORMANCE_METRIC_FORMAT_SUCCESS:
            return {
                ...state,
                performanceMetricFormatData: action.data,
            }
        case PERFORMANCE_METRIC_FORMAT_BY_ID_SUCCESS:
            return {
                ...state,
                performanceMetricSingleFormatData: action.data,
            }
        case PERFORMANCE_METRIC_FORMAT_FIELDS_SUCCESS:
            return {
                ...state,
                performanceMetricFormatFieldData: action.data,
            }

        case PERFORMANCE_WEIGHT_SUCCESS:
            return {
                ...state,
                performanceWeightData: action.data,

            }
        case PERFORMANCE_WEIGHT_SINGLE_SUCCESS:
            return {
                ...state,
                performanceWeightSingleData: action.data,
            }
        case PERFORMANCE_WEIGHT_FORMAT_SUCCESS:
            return {
                ...state,
                performanceWeightFormatData: action.data,
            }
        case PERFORMANCE_WEIGHT_FORMAT_BY_ID_SUCCESS:
            return {
                ...state,
                performanceWeightSingleFormatData: action.data,
            }
        case PERFORMANCE_WEIGHT_FORMAT_FIELDS_SUCCESS:
            return {
                ...state,
                performanceWeightFormatFieldData: action.data,
            }
        default:
            return state;
    }
};

export default PerformanceMetricReducer;