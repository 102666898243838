import {
  QUOTA_SUCCESS,
  QUOTA_SINGLE_SUCCESS,
  QUOTA_FORMAT_SUCCESS,
  QUOTA_FORMAT_BY_ID_SUCCESS,
  QUOTA_FORMAT_FIELDS_SUCCESS,
  FETCH_START,
  FETCH_END,
  TERRITORY_FORMAT_SUCCESS,
  TERRITORY_FORMAT_BY_ID_SUCCESS,
  TERRITORY_FORMAT_FIELDS_SUCCESS,
  TERRITORY_UPLOAD_SUCCESS,
} from "./ActionTypes";
import axios from "../../../axios.conf";
import { toast } from "react-toastify";
import { ServerError } from "../../../library/constants";
import { history } from "../../../components/Helpers";
import secureLocalStorage from "react-secure-storage";

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};
export const getQuotaData = (pagination, limit) => {
  return (dispatch) => {
    dispatch(fetchStart())
    getQuotaDataApi(pagination, limit)
      .then((response) => {
        dispatch(fetchEnd())
        dispatch(getQuotaDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd())
      });
  };
};

const getQuotaDataApi = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("quota-data?page=" + pagination + "&&limit=" + limit, {
    headers: headers,
  });
  return result;
};

export const getQuotaDataSuccess = (data) => {
  return {
    type: QUOTA_SUCCESS,
    data,
  };
};

export const createQuota = (data, type, id) => {
  return (dispatch) => {
    createQuotaAPI(data, type, id)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(getQuotaData(1, 30));
        if (type === "quotaPage" || type === "edit") {
          history.push("/quota-management");
        }
      })
      .catch((error) => { });
  };
};

const createQuotaAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (type === "delete") {
    result = axios.delete("quota-data/" + id, { headers: headers });
  } else if (type === "edit") {
    result = axios.put("quota-data/" + id, data, { headers: headers });
  } else {
    result = axios.post("quota-data", data, { headers: headers });
  }
  return result;
};

export const getQuotaSingleItem = (id) => {
  return (dispatch) => {
    getQuotaSingleItemAPI(id).then((response) => {
      dispatch(getQuotaSingleItemSuccess(response.data.response));
    });
  };
};
const getQuotaSingleItemAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("quota-data/" + id, { headers: headers });
  return result;
};

export const getQuotaSingleItemSuccess = (data) => {
  return {
    type: QUOTA_SINGLE_SUCCESS,
    data,
  };
};

//format

export const getQuotaFormatData = () => {
  return (dispatch) => {
    getQuotaFormatDataApi()
      .then((response) => {
        dispatch(getQuotaFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};
const getQuotaFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("upload-format?app-functionality=QuotaDataFormat", {
    headers: headers,
  });
  return result;
};
export const getQuotaFormatDataSuccess = (data) => {
  return {
    type: QUOTA_FORMAT_SUCCESS,
    data,
  };
};
export const getQuotaFormatDataById = (id) => {
  return (dispatch) => {
    getQuotaFormatDataByIdApi(id)
      .then((response) => {
        dispatch(getQuotaFormatDataByIdSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};
const getQuotaFormatDataByIdApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("quota-data-format/" + id, { headers: headers });
  return result;
};
export const getQuotaFormatDataByIdSuccess = (data) => {
  return {
    type: QUOTA_FORMAT_BY_ID_SUCCESS,
    data,
  };
};
//get membership format fields
export const getQuotaFormatFields = () => {
  return (dispatch) => {
    getQuotaFormatFieldsApi()
      .then((response) => {
        dispatch(getQuotaFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};
const getQuotaFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("upload-format-fields?app-functionality=QuotaDataFormat", {
    headers: headers,
  });
  return result;
};
export const getQuotaFormatFieldsSuccess = (data) => {
  return {
    type: QUOTA_FORMAT_FIELDS_SUCCESS,
    data,
  };
};
export const updateQuotaFormatData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateQuotaFormatDataApi(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          history.push("/quota-management/quota-management-excel-upload");
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};
const updateQuotaFormatDataApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.put(
    "upload-format/" + id + "?app-functionality=QuotaDataFormat",
    data,
    { headers: headers }
  );
  return result;
};
//add membership format
export const addQuotaFormatData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addQuotaFormatDataApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        //dispatch(getQuotaFormatData())
        toast.success(response.data.response);
        history.push("/quota-management/quota-management-excel-upload");
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};
const addQuotaFormatDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("upload-format?app-functionality=QuotaDataFormat", data, {
    headers: headers,
  });
  return result;
};
// delete membership format
export const deleteQuotaFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteQuotaFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success("Successfully deleted");
          dispatch(getQuotaFormatData());
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};
const deleteQuotaFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.delete("upload-format/" + id, { headers: headers });
  return result;
};
export const uploadQuotaFile = (id, file) => {
  return (dispatch) => {
    uploadQuotaFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          //window.location.reload();
          toast.success("File has been successfully uploaded.");
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadQuotaFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
    "Content-Type": "multipart/form-data;",
  };
  var result;
  result = axios.post("quota-data-upload?format_id=" + id, file, {
    headers: headers,
  });
  return result;
};
// territory format
export const getTerritoryFormatData = () => {
  return (dispatch) => {
    getTerritoryFormatDataApi()
      .then((response) => {
        dispatch(getTerritoryFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};
const getTerritoryFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("upload-format?app-functionality=TerritoryMasterFormats", {
    headers: headers,
  });
  return result;
};
export const getTerritoryFormatDataSuccess = (data) => {
  return {
    type: TERRITORY_FORMAT_SUCCESS,
    data,
  };
};
export const getTerritoryFormatDataById = (id) => {
  return (dispatch) => {
    getTerritoryFormatDataByIdApi(id)
      .then((response) => {
        dispatch(getTerritoryFormatDataByIdSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};
const getTerritoryFormatDataByIdApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "upload-format/" + id + "?app-functionality=TerritoryMasterFormats",
    { headers: headers }
  );
  return result;
};
export const getTerritoryFormatDataByIdSuccess = (data) => {
  return {
    type: TERRITORY_FORMAT_BY_ID_SUCCESS,
    data,
  };
};
export const getTerritoryFormatFields = () => {
  return (dispatch) => {
    getTerritoryFormatFieldsApi()
      .then((response) => {
        dispatch(getTerritoryFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};
const getTerritoryFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "upload-format-fields?app-functionality=TerritoryMasterFormats",
    { headers: headers }
  );
  return result;
};
export const getTerritoryFormatFieldsSuccess = (data) => {
  return {
    type: TERRITORY_FORMAT_FIELDS_SUCCESS,
    data,
  };
};
export const updateTerritoryFormatData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateTerritoryFormatDataApi(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          history.push("/territory/territory-excel-upload");
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};
const updateTerritoryFormatDataApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.put(
    "upload-format/" + id + "?app-functionality=TerritoryMasterFormats",
    data,
    { headers: headers }
  );
  return result;
};
export const addTerritoryFormatData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addTerritoryFormatDataApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        //dispatch(getTerritoryFormatData())
        toast.success(response.data.response);
        history.push("/territory/territory-excel-upload");
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};
const addTerritoryFormatDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post(
    "upload-format?app-functionality=TerritoryMasterFormats",
    data,
    { headers: headers }
  );
  return result;
};
export const deleteTerritoryFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteTerritoryFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          dispatch(getTerritoryFormatData());
          // window.location.reload();
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};
const deleteTerritoryFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.delete("upload-format/" + id, { headers: headers });
  return result;
};
// //upload File
export const uploadTerritoryFile = (id, file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadTerritoryFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadTerritoryFileSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          dispatch(fetchEnd());
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadTerritoryFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
    "Content-Type": "multipart/form-data;",
  };
  var result;
  result = axios.post("territory-upload?format_id=" + id, file, {
    headers: headers,
  });
  return result;
};
export const uploadTerritoryFileSuccess = (data) => {
  return {
    type: TERRITORY_UPLOAD_SUCCESS,
    data,
  };
};
