import {
    ERROR_LOGS_SUCCESS,
    MESSAGE_LOGS_SUCCESS,
    FETCH_START,
    FETCH_END,
} from '../../actions/AuditLab/ActionTypes';
const initialState = {
    loading: false,
    errorLogData: [],
    editAttributeData: null,
    attributeNameList:[]
};

const AuditLabReducer = (state = initialState, action) => {
    switch (action.type) {
        case ERROR_LOGS_SUCCESS:
            return {
                ...state,
                errorLogData: action.data,
                loading: false
            };
            case MESSAGE_LOGS_SUCCESS:
                return {
                    ...state,
                    messageLogData: action.data,
                    loading: false
                }
        case FETCH_START:
            return {
                ...state,
                loading: true
            }
        case FETCH_END:
            return {
                ...state,
                loading: false
            }
        default:
            return state;
    }
};

export default AuditLabReducer;