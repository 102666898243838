import {
    BASIS_MAPPING_ALL,
   BASIS_MAPPING_SINGLE,
    FETCH_START,
    FETCH_END
} from '../../../actions/CalculationDesigner/BasisMapping/ActionTypes';
const initialState = {
    BasisMapData:[],
    BasisMapDataSingle:[],
    loading:false,
}
const AddKeyFigureReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_START:
            return {
                loading: true
            };
        case FETCH_END:
            return {
                loading: false
            };
        case BASIS_MAPPING_ALL:
            return {
                ...state,
                BasisMapData: action.data
            };
        case BASIS_MAPPING_SINGLE:
            return {
                ...state,
                BasisMapDataSingle: action.data
            };
        default:
            return state;
        }

}
export default AddKeyFigureReducer;
