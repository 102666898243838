import { FORMULA_LIST_SUCCESS, FORMULA_DETAIL_SUCCESS } from "./ActionTypes";
import axios from "../../../../axios.conf";
import { history } from "../../../../components/Helpers";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";

export const getFormulasListSuccess = (data) => {
  return {
    type: FORMULA_LIST_SUCCESS,
    data,
  };
};

export const getFormulasList = (pagination, limit,sourceType) => {
  return (dispatch) => {
    getFormulasListAPI(pagination, limit,sourceType)
      .then((response) => {
        dispatch(getFormulasListSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};

const getFormulasListAPI = (pagination, limit,sourceType) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if(sourceType)
  result = axios.get(
    "formula?page=" + pagination +"&limit=" +limit +
     "&source_data_type="+sourceType+"&application_type=" +sessionStorage.getItem("application"),
    { headers: headers }
  );
  else
  result = axios.get(
    "formula?page=" + pagination +"&limit=" +limit +
      "&application_type=" +sessionStorage.getItem("application"),
    { headers: headers }
  );
  return result;
};

export const submitFormula = (data, id, type) => {
  return (dispatch) => {
    submitFormulaAPI(data, id, type)
      .then((response) => {
        history.push("/formula-lab");
        dispatch(getFormulasList());
        toast.success(response.data.response);
      })
      .catch((error) => {});
  };
};

const submitFormulaAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (type === "put") {
    result = axios.put("formula/" + id, data, { headers: headers });
  } else if (type === "delete") {
    result = axios.delete("formula/" + id, data, { headers: headers });
  } else {
    result = axios.post("formula", data, { headers: headers });
  }
  return result;
};

//edit formula
export const getFormulaDetails = (id) => {
  return (dispatch) => {
    getFormulaDetailsAPI(id)
      .then((response) => {
        dispatch(getFormulaDetailsSuccess(response.data.response));
        history.push("/formula-lab/edit-formula-lab/" + id);
      })
      .catch((error) => {});
  };
};

const getFormulaDetailsAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("formula/" + id, { headers: headers });
  return result;
};

export const getFormulaDetailsSuccess = (data) => {
  return {
    type: FORMULA_DETAIL_SUCCESS,
    data,
  };
};
