import {
  LOCKED_PAGE,
} from "./ActionTypes";
import axios from "../../../../axios.conf";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";

// export const fetchStart = () => {
//   return {
//     type: FETCH_START,
//   };
// };

// export const fetchEnd = () => {
//   return {
//     type: FETCH_END,
//   };
// };
// export const getLockData = (data) => {
//   return (dispatch) => {
//     getLockAPI(data)
//       .then((response) => {
//         return response.data.response
//       })
//       .catch((error) => {});
//   };
// };

export const getLockData = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("/is-editable", data, { headers: headers });
  return result;
};
export const updateLockedPage = (data) => {
  return {
    type: LOCKED_PAGE,
    data
  };
};
export const removeLock = (data) => {
  console.log("done2")
  return (dispatch) => {
    removeLockAPI(data)
      .then((response) => {
        dispatch(updateLockedPage({}))
      })
      .catch((error) => { });
  };
};

const removeLockAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("/control-modification", data, { headers: headers });
  return result;
};

