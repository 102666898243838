import {
  HOLIDAY_LIST_SUCCESS,
  HOLIDAY_LIST_FAIL,
  EDIT_HOLIDAY_SUCCESS,
  EDIT_HOLIDAY_FAIL,
  FETCH_START,
  FETCH_END,
} from "./ActionTypes";
import axios from "../../../axios.conf";
import { toast } from "react-toastify";
import { ServerError } from "../../../library/constants";
import secureLocalStorage from "react-secure-storage";

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};

export const getAllHolidayDataSuccess = (data) => {
  let eventGuid = 0;
  var tempRows = [];
  if (data && data.holiday.length > 0) {
    data.holiday.map((e) => {
      tempRows.push({
        id: String(eventGuid++),
        title: "Holiday",
        start: e,
        allDay: true,
        display: "background",
      });
    });
    data.holiday = tempRows;
  }

  return {
    type: HOLIDAY_LIST_SUCCESS,
    data,
  };
};

export const getAllHolidayDataFailed = (error) => {
  return {
    type: HOLIDAY_LIST_FAIL,
    error,
  };
};

//get all Holiday Data
export const getAllHolidayData = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getAllHolidayDataApi()
      .then((response) => {
        dispatch(getAllHolidayDataSuccess(response.data.response));
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(getAllHolidayDataFailed(error));
        //toast.error(ServerError);
      });
  };
};

const getAllHolidayDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("holiday", { headers: headers });
  return result;
};

//create holiday data
export const createHolidayData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addHolidayDataAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
        dispatch(getAllHolidayData());
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const addHolidayDataAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("holiday", data, { headers: headers });
  return result;
};

//edit Holiday Data
export const editHolidayData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    editHolidayDataAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
        dispatch(getAllHolidayData());
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const editHolidayDataAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.put("holiday", data, { headers: headers });
  return result;
};
