import {
    LOGIN_FAILED,
    LOGIN_SUCCESS,
    FETCH_START,
    FETCH_END
} from '../../../actions/Auth/Login/ActionTypes';

const initialState = {
    userData: {},
    error: false,
    loadingLogin: false
};

const LoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                userData: action.userData,
                loadingLogin: false,
                error: false
            };
        case LOGIN_FAILED:
            return {
                ...state,
                userData: {},
                error: true
            };
        case FETCH_START:
            return {
                loadingLogin: true
            }
        case FETCH_END:
            return {
                loadingLogin: false
            }
        default:
            return state;
    }
};

export default LoginReducer;