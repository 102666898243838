import {
    HELP_DESK_ALL,
    HELP_DESK_SINGLE,
    FETCH_START,
    FETCH_END,
} from '../../../actions/CalculationDesigner/HelpDesk/ActionTypes';
const initialState = {
    HelpDeskData: [],
    HelpDeskDataSingle: [],
    loading: false,
}
const HelpDeskReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_START:
            return {
                loading: true
            };
        case FETCH_END:
            return {
                loading: false
            };
        case HELP_DESK_ALL:
            return {
                ...state,
                HelpDeskData: action.data
            };
        case HELP_DESK_SINGLE:
            return {
                ...state,
                HelpDeskDataSingle: action.data
            };
        default:
            return state;
    }
}
export default HelpDeskReducer;
