import { combineReducers } from 'redux';
import { LoginReducer, LockReducer } from './Auth';
import { AddContractReducer } from './Contracts';
import { AddExceptionReducer } from './Exception';
import { AddMultipleConfigurationReducer } from './Configuration';
import { CalculationSimulationReducer, OperationalReportsReducer } from './Simulations';
import { DataSetupReducer } from './DataSetup';
import { DashboardReducer } from './Initial';
import { persistReducer } from 'redux-persist';
import { ProfileReducer } from './Profile';
import storage from 'redux-persist/lib/storage';
import { AddCustomerReducer } from './Customers';
import { FormulaLabReducer } from './FormulaLab';
import { AddKeyFigureReducer, AttributeMapReducer, BasisMapReducer, AnalyticsDesignerReducer, HelpDeskReducer } from './CalculationDesigner';
import { AttributesReducer } from './Attributes';
import { HolidayCalendarReducer } from './HolidayCalendar';
import { FinancialCloseReducer } from './FinancialClose';
import { CalculationAnalysisReducer } from './Calculation Analysis';
import { QuotaManagementReducer } from './QuotaManagement';
import { ExternalFormulaResults } from './ExternalFormulaResults';
import { PerformanceMetricReducer } from './PerformanceMetric';
import { UoMReducer } from './UoM';
import { ProductFeatureReducer } from './ProductFeature';
import { PromotionReducer } from './Promotion';
import { AuditLabReducer } from './AuditLab';
import { PricingReducer } from './Pricing';
import { ForecastingReducer } from './Forecasting';
import { ProfitOptimizationReducer } from './ProfitOptimizationConfig';
import { QueryFieldReducer } from './QueryField';
import AnamolyReducer from './AnamolyConfig/AnamolyConfig.Reducer';
import CollaborationDesignerReducer from './CollaborationDesigner/CollaborationDesigner.Reducer';
import ChatGptReducer from './ChatGpt/ChatGpt.Reducer';
import { DocumentManagementReducer } from './DocumentManagement';
const persistConfig = {
  key: 'auth',
  storage: storage,
  whitelist: ['addMultipleConfigurationData', 'initialData', 'addContractData', 'addExceptionData', 'simulationData', 'operationalReportsData', 'profileData', 'customerData', 'AddKeyFigureData', 'attributeListData', 'holidayCalendarData', 'financialCloseData']
};

const RootReducer = combineReducers({
  loginData: LoginReducer,
  addContractData: AddContractReducer,
  addExceptionData: AddExceptionReducer,
  addMultipleConfigurationData: AddMultipleConfigurationReducer,
  initialData: DashboardReducer,
  simulationData: CalculationSimulationReducer,
  dataSetupData: DataSetupReducer,
  operationalReportsData: OperationalReportsReducer,
  profileData: ProfileReducer,
  customerData: AddCustomerReducer,
  FormulaLabData: FormulaLabReducer,
  AddKeyFigureData: AddKeyFigureReducer,
  AttributeMapData: AttributeMapReducer,
  attributeListData: AttributesReducer,
  holidayCalendarData: HolidayCalendarReducer,
  financialCloseData: FinancialCloseReducer,
  calculationAnalysisData: CalculationAnalysisReducer,
  BasisMapData: BasisMapReducer,
  quotaManagementData: QuotaManagementReducer,
  externalFormulaResultsData: ExternalFormulaResults,
  performanceMetricData: PerformanceMetricReducer,
  uomData: UoMReducer,
  productFeatureData: ProductFeatureReducer,
  promotionData: PromotionReducer,
  auditLabData: AuditLabReducer,
  pricingData: PricingReducer,
  forecastData: ForecastingReducer,
  profitOptimizationData: ProfitOptimizationReducer,
  AnalyticsDesignerData: AnalyticsDesignerReducer,
  HelpDeskData: HelpDeskReducer,
  QueryFieldData: QueryFieldReducer,
  AnamolyData: AnamolyReducer,
  CollaborationDesignerData: CollaborationDesignerReducer,
  ChatGptData: ChatGptReducer,
  DocumentManagement: DocumentManagementReducer,
  LockData: LockReducer
});

export default persistReducer(persistConfig, RootReducer);