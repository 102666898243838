import {
  ADD_ELIGIBILITY_RULE,
  ADD_ELIGIBILITY_RULE_FAILED,
  ADD_ELIGIBILITY_RULE_SUCCESS,
  ADD_CALCULATION_RULE_FAILED,
  ADD_CALCULATION_RULE,
  ADD_EXCEPTION_GENERAL_DATA,
  CHANGED_EXCEPTION_DETAIL_SUCCESS,
  EXCEPTION_DATA,
  EXCEPTION_DATA_COMPLETE,
  EXCEPTION_DETAIL_DATA,
  CALCULATION_RULE_SUCCESS,
  EXCEPTION_NUMBER,
  FETCH_START,
  FETCH_END,
  FETCH_START_UPLOAD,
  FETCH_END_UPLOAD,
  UPLOAD_ATTACHMENT_SUCCESS,
  SHOW_EXCEPTION_POPUP,
  EXCEPTION_DATA_COMPARE,
  UPDATED_EXCEPTION_LIST,
  EXCEPTION_CHANGE_LOG,
  EXCEPTION_ALL_APPTYPE,
  EXCEPTION_DATA_LIST,
  UPDATED_EXCEPTION_ALL_LIST,
  FP_HISTORY_SUCCESS,
  MASS_EXCEPTION_UPDATE,
  MASS_EXCEPTION_UPDATE_ACTION,
} from "./ActionTypes";
import axios from "../../../../axios.conf";
import { toast } from "react-toastify";
import { ServerError } from "../../../../library/constants";
import { history } from "../../../../components/Helpers";
import secureLocalStorage from "react-secure-storage";

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};

export const addEligibilityRulesSuccess = (data) => {
  return {
    type: ADD_ELIGIBILITY_RULE,
    data,
  };
};

export const eligibilityRulesAdded = () => {
  return {
    type: ADD_ELIGIBILITY_RULE_SUCCESS,
  };
};

export const addEligibilityRulesFailed = (data) => {
  return {
    type: ADD_ELIGIBILITY_RULE_FAILED,
    data,
  };
};

export const addCalculationRuleFailed = (data) => {
  return {
    type: ADD_CALCULATION_RULE_FAILED,
    data,
  };
};

export const calculationRuleSuccess = () => {
  return {
    type: CALCULATION_RULE_SUCCESS,
  };
};

export const getExceptionsTableDataSuccess = (data) => {
  return {
    type: EXCEPTION_DATA,
    data,
  };
};

export const getExceptionsTableCompleteDataSuccess = (data) => {
  return {
    type: EXCEPTION_DATA_COMPLETE,
    data,
  };
};

export const getExceptionsDetailDataSuccess = (data) => {
  return {
    type: EXCEPTION_DETAIL_DATA,
    data,
  };
};

export const addEligibilityRules = (data) => {
  return (dispatch) => {
    getAddElligibityRulesApi(data)
      .then((response) => {
        toast.success("Eligiility Rules added !");
        dispatch(getEligibilityRulesTableData());
      })
      .catch((error) => {
        //toast.error("Something went wrong.");
        dispatch(addEligibilityRulesFailed(error));
      });
  };
};

export const editEligibilityRules = (data, id) => {
  return (dispatch) => {
    getEditEligibilityRulesApi(data, id)
      .then((response) => {
        toast.success("Eligibility rules have been updated successfully!");
        dispatch(getEligibilityRulesTableData());
      })
      .catch((error) => {
        //toast.error("Something went wrong.");
        dispatch(addEligibilityRulesFailed(error));
      });
  };
};

export const getExceptionsTableData = (
  pagination,
  limit,
  approval,
  changeException
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getExceptionsTableApi(pagination, limit, approval, changeException)
      .then((response) => {
        dispatch(
          getExceptionsTableDataComplete(response.data.response.total_record)
        );
        dispatch(fetchEnd());
        dispatch(getExceptionsTableDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        if (
          error.response &&
          error.response.data &&
          error.response.data.complete_error_message
        ) {
          toast.warn(error.response.data.complete_error_message);
        }
      });
  };
};
//complete EXCEPTION data starts here
export const getExceptionsTableDataComplete = (tableLimit) => {
  return (dispatch) => {
    getExceptionsCompleteTableCompleteApi(tableLimit)
      .then((response) => {
        dispatch(getExceptionsTableCompleteDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error("Something went wrong.");
      });
  };
};

const getExceptionsCompleteTableCompleteApi = (tableLimit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "Exceptions?limit=" +
      tableLimit +
      "&application_type=" +
      sessionStorage.getItem("application"),
    { headers: headers }
  );
  return result;
};

export const getExceptionDetailsData = (id) => {
  return (dispatch) => {
    getExceptionsDetailApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(addExceptionGeneralDataSuccess(response.data.response));
        dispatch(ExceptionNumber(response.data.response.EXCEPTION_number));
      })
      .catch((error) => {
        //toast.error("Something went wrong.");
      });
  };
};

export const getExceptionViewData = (id, type) => {
  return (dispatch) => {
    getExceptionsDetailApi(id)
      .then((response) => {
        if (type != "massUpdate")
          history.push("/EXCEPTION-setup/view-EXCEPTION/" + id);
        dispatch(fetchEnd());
        dispatch(addExceptionGeneralDataSuccess(response.data.response));
        dispatch(ExceptionNumber(response.data.response.EXCEPTION_number));
      })
      .catch((error) => {
        //toast.error("Something went wrong.");
      });
  };
};

export const getEligibilityRulesTableData = (id) => {
  return (dispatch) => {
    getAddElligibityRulesTableApi()
      .then((response) => {
        dispatch(addEligibilityRulesSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(addEligibilityRulesSuccess([]));
      });
  };
};

export const getCalculationRulesTableData = () => {
  return (dispatch) => {
    getAddCalculationRulesTableApi()
      .then((response) => {
        dispatch(addCalculationRulesSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(addCalculationRulesSuccess([]));
        if (error.response) {
        } else {
          //toast.error(ServerError);
        }
      });
  };
};

export const addCalculationRulesSuccess = (data) => {
  return {
    type: ADD_CALCULATION_RULE,
    data,
  };
};

export const addCalculationRules = (data) => {
  return (dispatch) => {
    getAddCalculationRulesApi(data)
      .then((response) => {
        toast.success("Calculation rules have been added successfully!");
        dispatch(getCalculationRulesTableData());
        dispatch(calculationRuleSuccess());
      })
      .catch((error) => {
        dispatch(addCalculationRuleFailed(error));
        //toast.error("Something went wrong.");
      });
  };
};

export const editCalculationRules = (data, id) => {
  return (dispatch) => {
    getEditCalculationRulesApi(data, id)
      .then((response) => {
        toast.success("Calculation rules have been updated successfully!");
        dispatch(getCalculationRulesTableData());
      })
      .catch((error) => {
        //toast.error("Something went wrong.");
        dispatch(addCalculationRuleFailed(error));
      });
  };
};

export const addExceptionGeneralDataSuccess = (data) => {
  return {
    type: ADD_EXCEPTION_GENERAL_DATA,
    data,
  };
};

export const ExceptionNumber = (data) => {
  return {
    type: EXCEPTION_NUMBER,
    data,
  };
};

export const addExceptionGeneralData = (data, forced) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getAddExceptionApi(data, forced)
      .then((response) => {
        dispatch(fetchEnd());
        if (
          response &&
          response.data &&
          response.data.response &&
          response.data.response.condition_id_message
        ) {
          toast.warn(response.data.response.condition_id_message);
        }
        if (response.status === 208) {
          dispatch(showExceptionOverlapPopup(true));
        } else {
          dispatch(
            addExceptionGeneralDataSuccess(response.data.response.EXCEPTION)
          );
          history.push("/EXCEPTION-setup");
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        if (
          error.response &&
          error.response.data &&
          error.response.data.complete_error_message
        ) {
          //toast.error(error.response.data.complete_error_message);
        } else {
          //toast.error("Something went wrong.");
        }
      });
  };
};

export const resetShowExceptionOverlapPopup = () => {
  return (dispatch) => {
    dispatch(showExceptionOverlapPopup(false));
  };
};

export const showExceptionOverlapPopup = (data) => {
  return {
    type: SHOW_EXCEPTION_POPUP,
    data,
  };
};

const getAddExceptionApi = (data, forced) => {
  for (var propName in data) {
    if (data[propName] === "" || data[propName] === null) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  var currenId = localStorage.getItem("currentAddEXCEPTIONID");
  if (data === 0) {
    result = axios.post("exceptions", { headers: headers });
  } else {
    if (localStorage.getItem("currentAddEXCEPTIONID")) {
      result = axios.post("exceptions/" + currenId, data, { headers: headers });
    } else {
      if (forced === true) {
        result = axios.post("exceptions?force=true", data, {
          headers: headers,
        });
      } else {
        result = axios.post("exceptions", data, { headers: headers });
      }
    }
  }
  return result;
};

const getAddElligibityRulesApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post(
    "EXCEPTIONs/" +
      localStorage.getItem("currentAddExceptionID") +
      "/eligibility_rules",
    data,
    { headers: headers }
  );
  return result;
};

const getEditEligibilityRulesApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.put("exception_eligibility_rules/" + id, data, {
    headers: headers,
  });
  return result;
};

const getEditCalculationRulesApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.put("Exception_calculation_rules/" + id, data, {
    headers: headers,
  });
  return result;
};

const getAddElligibityRulesTableApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "exceptions/" +
      localStorage.getItem("currentAddExxceptionID") +
      "/eligibility_rules",
    { headers: headers }
  );
  return result;
};

const getAddCalculationRulesApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post(
    "exceptions/" +
      localStorage.getItem("currentAddEXCEPTIONID") +
      "/calculation_rules",
    data,
    { headers: headers }
  );
  return result;
};

const getAddCalculationRulesTableApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "Exceptions/" +
      localStorage.getItem("currentAddExceptionID") +
      "/calculation_rules",
    { headers: headers }
  );
  return result;
};

const getExceptionsTableApi = (
  pagination,
  limit,
  approval,
  EXCEPTIONChange
) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (EXCEPTIONChange === "No Change Exception") {
    if (approval === "submit") {
      result = axios.get(
        "exceptions?is_update_approval_exception=false&approval_status=submit_approval&page=" +
          pagination +
          "&&limit=" +
          limit +
          "&application_type=" +
          sessionStorage.getItem("application"),
        { headers: headers }
      );
    } else {
      result = axios.get(
        "exceptions?is_update_approval_EXCEPTION=false&page=" +
          pagination +
          "&&limit=" +
          limit +
          "&application_type=" +
          sessionStorage.getItem("application"),
        { headers: headers }
      );
    }
  } else {
    if (approval === "submit") {
      result = axios.get(
        "exceptions?approval_status=submit_approval&page=" +
          pagination +
          "&&limit=" +
          limit +
          "&application_type=" +
          sessionStorage.getItem("application"),
        { headers: headers }
      );
    } else {
      result = axios.get(
        "exceptions?page=" +
          pagination +
          "&&limit=" +
          limit +
          "&application_type=" +
          sessionStorage.getItem("application"),
        { headers: headers }
      );
    }
  }
  return result;
};

const getExceptionsDetailApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("exceptions/" + id, { headers: headers });
  return result;
};

export const cloneException = (id) => {
  const pagination = 1;
  const limit = 10;
  return (dispatch) => {
    getCloneExceptionApi(id)
      .then((response) => {
        toast.success("Exception clonned successfully.");
        dispatch(getExceptionsTableDataList());
      })
      .catch((error) => {
        //toast.error("Something went wrong.");
      });
  };
};

const getCloneExceptionApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("exceptions/" + id + "/clone", { headers: headers });
  return result;
};

//delete EXCEPTION
export const deleteException = (id) => {
  const pagination = 1;
  const limit = 10;
  return (dispatch) => {
    getDeleteExceptionApi(id)
      .then((response) => {
        toast.success("Deleted successfully.");
        dispatch(getExceptionsTableDataList());
      })
      .catch((error) => {
        //toast.error("Something went wrong.");
      });
  };
};

//change request details
export const changeExceptionRequestDetails = (id, updatedId, type) => {
  return (dispatch) => {
    getExceptionsDetailApi(id)
      .then((response) => {
        dispatch(getChangedExceptionDetailsSuccess(response.data.response));
        if (type === "compare") {
          history.push("/approval-reject/change-request/" + updatedId);
        } else {
          history.push("/exception-setup/change-request-detail/" + id);
        }
      })
      .catch((error) => {
        //toast.error("Something went wrong.");
      });
  };
};
export const getChangedExceptionDetailsSuccess = (data) => {
  return {
    type: CHANGED_EXCEPTION_DETAIL_SUCCESS,
    data,
  };
};

const getDeleteExceptionApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.delete("exceptions/" + id, { headers: headers });
  return result;
};

//put EXCEPTION
export const putExceptionGeneralData = (data, status) => {
  return (dispatch) => {
    dispatch(fetchStart());
    putAddExceptionApi(data, status)
      .then((response) => {
        dispatch(fetchEnd());
        history.push("/exception-setup");
        if (
          response.data &&
          response.data.response &&
          response.data.response.complete_error_message &&
          response.data.response.complete_error_message
        )
          if (
            response.data &&
            response.data.response &&
            response.data.response.date_message
          ) {
            //toast.error(response.data.response.complete_error_message);
            toast.warn(response.data.response.date_message);
          }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        if (
          error.response &&
          error.response.data &&
          error.response.data.complete_error_message
        ) {
          //toast.error(error.response.data.complete_error_message);
        } else {
          //toast.error("Something went wrong.");
        }
      });
  };
};

const putAddExceptionApi = (data, status) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  var currenId = localStorage.getItem("currentAddExceptionID");
  if (status === "CR") {
    result = axios.post(
      "exceptions/" +
        currenId +
        "/update-request?application_type=" +
        sessionStorage.getItem("application"),
      data,
      { headers: headers }
    );
  } else {
    result = axios.post(
      "exceptions/" +
        currenId +
        "?application_type=" +
        sessionStorage.getItem("application"),
      data,
      { headers: headers }
    );
  }
  return result;
};

//put EXCEPTION
export const newExceptionAPIForAccRe = (status, bulk, id) => {
  return (dispatch) => {
    newAPIExceptionForAccReAPI(status, bulk, id)
      .then((response) => {
        if (bulk === "bulk") {
          dispatch(getExceptionsTableData(1, 10, "submit"));
        } else {
        }
        history.push("/exception-setup");
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.complete_error_message
        ) {
          //toast.error(error.response.data.complete_error_message);
        } else {
          //toast.error("Something went wrong.");
        }
      });
  };
};

const newAPIExceptionForAccReAPI = (status, bulk, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (bulk === "bulk") {
    result = axios.post(
      "EXCEPTIONs/" +
        id +
        "/update-request/" +
        status +
        "?application_type=" +
        sessionStorage.getItem("application"),
      { headers: headers }
    );
  } else {
    result = axios.post(
      "exceptions/" +
        localStorage.getItem("currentAddExceptionID") +
        "/update-request/" +
        status +
        "?application_type=" +
        sessionStorage.getItem("application"),
      { headers: headers }
    );
  }
  return result;
};
//search starts here
export const searchExceptions = (keyword) => {
  return (dispatch) => {
    if (keyword) {
      getSearchApi(keyword)
        .then((response) => {
          dispatch(getExceptionsTableDataSuccess(response.data.response));
        })
        .catch((error) => {});
    } else {
      dispatch(getExceptionsTableData(0, 10));
    }
  };
};

const getSearchApi = (keyword) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("exceptions?keyword=" + keyword, { headers: headers });
  return result;
};
//search ends here

//upload attachment
export const uploadAttachment = (file) => {
  return (dispatch) => {
    dispatch(fetchStartUpload());
    uploadAttachmentApi(file)
      .then((response) => {
        dispatch(fetchEndUpload());
        dispatch(uploadAttachmentSuccess(response.data.response.locate_url));
        console.log(response.data.response.locate_url);
        if (localStorage.getItem("currentAddExceptionID"))
          dispatch(
            getExceptionDetailsData(
              localStorage.getItem("currentAddExceptionID")
            )
          );
      })
      .catch((error) => {
        dispatch(fetchEndUpload());
        //toast.error("File Uploading Error.");
      });
  };
};

export const fetchStartUpload = () => {
  return {
    type: FETCH_START_UPLOAD,
  };
};

export const fetchEndUpload = () => {
  return {
    type: FETCH_END_UPLOAD,
  };
};

const uploadAttachmentApi = (file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
    "Content-Type": "multipart/form-data;",
  };
  var result = axios.post("file-upload", file, { headers: headers });
  return result;
};

export const uploadAttachmentSuccess = (data) => {
  return {
    type: UPLOAD_ATTACHMENT_SUCCESS,
    data,
  };
};

//batch approval
export const batchExceptionApprove = (data, status, dataApproved) => {
  return (dispatch) => {
    batchApproveApi(data, status, dataApproved)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.response &&
          response.data.response.message
        ) {
          toast.success(response.data.response.message);
        }
        // dispatch(getExceptionsTableData(1, 10, "submit"));
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.complete_error_message
        ) {
          //toast.error(error.response.data.complete_error_message);
        } else {
          //toast.error("Something went wrong.");
        }
      });
  };
};

const batchApproveApi = (data, status, dataApproved) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var formData = {
    exception_ids: data,
    approval_status: status,
    // update_EXCEPTION_ids: dataApproved,
  };
  var result;
  // result = axios.post(
  //   "approval-request/bulk-approval/exception?application_type=" +
  //     sessionStorage.getItem("application"),
  //   formData,
  //   { headers: headers }
  // );
  result = axios.post(
    "approval-request/bulk-approval/exception?application_type=" +
      sessionStorage.getItem("application"),
    formData,
    { headers: headers }
  );
  return result;
};

//data for compare EXCEPTION
export const getExceptionDetailsDataCompare = (id) => {
  return (dispatch) => {
    getExceptionDetailsDataCompareAPI(id)
      .then((response) => {
        dispatch(getExceptionDetailsDataCompareSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error("Something went wrong.");
      });
  };
};

export const getExceptionDetailsDataCompareSuccess = (data) => {
  return {
    type: EXCEPTION_DATA_COMPARE,
    data,
  };
};

const getExceptionDetailsDataCompareAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("exceptions/" + id, { headers: headers });
  return result;
};

//get updated EXCEPTIONs list
export const updatedExceptionsList = (data) => {
  return (dispatch) => {
    updatedExceptionsListAPI(data).then((response) => {
      dispatch(updatedExceptionsListSuccess(response.data.response));
    });
  };
};

export const updatedExceptionsListSuccess = (data) => {
  return {
    type: UPDATED_EXCEPTION_LIST,
    data,
  };
};

const updatedExceptionsListAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "exceptions?update_exception_id.in=" +
      data.toString() +
      "&application_type=" +
      sessionStorage.getItem("application") +
      "&limit=0&update_request_closed=false&is_update_approval_EXCEPTION=true",
    { headers: headers }
  );
  return result;
};

// change log
export const getExceptionChangeLog = (id) => {
  return (dispatch) => {
    getExceptionChangeLogAPI(id).then((response) => {
      dispatch(getExceptionChangeLogAPISuccess(response.data.response));
    });
  };
};

const getExceptionChangeLogAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("exception-change-log?exception_id=" + id, {
    headers: headers,
  });
  return result;
};

export const getExceptionChangeLogAPISuccess = (data) => {
  return {
    type: EXCEPTION_CHANGE_LOG,
    data,
  };
};

//EXCEPTIONs by application type
export const getExceptionsTableDataByApplicationType = (appType) => {
  return (dispatch) => {
    getExceptionsTableDataByApplicationTypeAPI(appType)
      .then((response) => {
        dispatch(
          getExceptionsTableDataByApplicationTypeSuccess(response.data.response)
        );
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error("Something went wrong.");
      });
  };
};

const getExceptionsTableDataByApplicationTypeAPI = (appType) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "exceptions?application_type=" +
      appType +
      "&limit=0&is_update_approval_exception=false",
    { headers: headers }
  );
  return result;
};

export const getExceptionsTableDataByApplicationTypeSuccess = (data) => {
  return {
    type: EXCEPTION_ALL_APPTYPE,
    data,
  };
};
// get EXCEPTION by search Eligibility Rules
export const getExceptionsTableDataBySearchEligibilty = (data) => {
  return (dispatch) => {
    getExceptionsTableDataBySearchEligibiltyAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getExceptionsTableDataListSuccess(response.data.response));
        history.push({
          pathname: "/Exception-setup",
          state: "searchByException",
        });
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error("Something went wrong.");
      });
  };
};
const getExceptionsTableDataBySearchEligibiltyAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("exception-search-rule", data, { headers: headers });
  return result;
};
export const getExceptionsTableDataList = (pagination, page, app, filters) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getExceptionsTableListApi(pagination, page, app, filters)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getExceptionsTableDataListSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        if (
          error.response &&
          error.response.data &&
          error.response.data.complete_error_message
        ) {
          toast.warn(error.response.data.complete_error_message);
        } else {
          //toast.error("Something went wrong.");
        }
      });
  };
};

const getExceptionsTableListApi = (tableLimit, page, app, filters) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (page === "Designer Studio") {
    result = axios.get(
      "exception-list?is_update_approval_exception=false" +
        "&application_type=" +
        app,
      { headers: headers }
    );
  } else if (sessionStorage.getItem("application") === "Collaboration Portal") {
    result = axios.get(
      "collaboration-exception?is_update_approval_exception=false",
      { headers: headers }
    );
  } else if (sessionStorage.getItem("application") === "Audit Lab") {
    result = axios.get(
      "exception-list?is_update_approval_exception=false" +
        "&application_type=" +
        tableLimit,
      { headers: headers }
    );
  } else {
    if (tableLimit === "AllApps") {
      result = axios.get("exception-list?is_update_approval_exception=false", {
        headers: headers,
      });
    } else {
      result = axios.get(
        "EXCEPTION-list?is_update_approval_exception=false" +
          "&application_type=" +
          sessionStorage.getItem("application"),
        { params: filters, headers: headers }
      );
    }
  }
  return result;
};

export const getExceptionsTableDataListSuccess = (data) => {
  return {
    type: EXCEPTION_DATA_LIST,
    data,
  };
};

//get updated EXCEPTIONs list
export const updatedExceptionsAllList = (data) => {
  return (dispatch) => {
    updatedExceptionsAllListAPI(data).then((response) => {
      dispatch(updatedExceptionsAllListSuccess(response.data.response));
    });
  };
};

export const updatedExceptionsAllListSuccess = (data) => {
  return {
    type: UPDATED_EXCEPTION_ALL_LIST,
    data,
  };
};

const updatedExceptionsAllListAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "exception-list?update_exception_id.in=" +
      data.toString() +
      "&application_type=" +
      sessionStorage.getItem("application") +
      "&update_request_closed=false&is_update_approval_exception=true",
    { headers: headers }
  );
  return result;
};

//complete EXCEPTION data starts here
export const downloadAllExceptions = () => {
  toast.info(
    "Downloading has been started in background. File will be downloaded automatically once ready."
  );
  return (dispatch) => {
    downloadAllExceptionsAPI()
      .then((response) => {
        dispatch(downloadSheet(response.data.response.records));
      })
      .catch((error) => {
        //toast.error("Something went wrong.");
      });
  };
};

const downloadAllExceptionsAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("exceptions?limit=0", { headers: headers });
  return result;
};

//download sheet starts heare
export const downloadSheet = (data) => {
  return (dispatch) => {
    getDownloadSheetApi(data).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      var date = Date.now();
      link.setAttribute("download", date + ".xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };
};

const getDownloadSheetApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  const result = axios.post("generate-excel-sheet/exceptions", data, {
    headers: headers,
    responseType: "blob",
  });
  return result;
};

//financial postings history
export const fpHistory = (id, pagination, limit) => {
  return (dispatch) => {
    fpHistoryAPI(id, pagination, limit).then((response) => {
      dispatch(fpHistorySuccess(response.data.response));
      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.records.length > 0
      ) {
        history.push("/exception-setup/fp-history/" + id);
      } else {
        toast.warn("No Records Found.");
      }
    });
  };
};

const fpHistoryAPI = (id, pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  const result = axios.get(
    "finpost-history/" + id + "?page=" + pagination + "&limit=" + limit,
    { headers: headers }
  );
  return result;
};

export const fpHistorySuccess = (data) => {
  return {
    type: FP_HISTORY_SUCCESS,
    data,
  };
};

export const ediUpload = (file, type, id) => {
  return (dispatch) => {
    ediUploadAPI(file, type, id)
      .then((response) => {
        if (type === "download") {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          var date = Date.now();
          link.setAttribute("download", date + ".txt"); //or any other extension
          document.body.appendChild(link);
          link.click();
          toast.success("File will be downloaded in the background.");
        } else {
          toast.success("File has been uploaded successfully");
        }
      })
      .catch((error) => {
        if (type === "download") toast.error("File Download Failed.");
        else toast.error("File Uploading Error.");
      });
  };
};

const ediUploadAPI = (file, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
    "Content-Type": "multipart/form-data;",
  };
  if (type === "download") {
    var result = axios.get("exception-edi-download?id=" + id, {
      headers: headers,
    });
  } else {
    var result = axios.post("exception-edi-upload", file, { headers: headers });
  }
  return result;
};

export const massUpdateException = (appType, data) => {
  return (dispatch) => {
    massUpdateExceptionAPI(appType, data)
      .then((response) => {
        dispatch(massUpdateExceptionSuccess(response.data.response));
        history.push("/mass-exception-update-result");
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error("Something went wrong.");
      });
  };
};

const massUpdateExceptionAPI = (appType, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post(
    "exceptions/mass-update/lookup?application_type=" + appType,
    data,
    { headers: headers }
  );
  return result;
};

export const massUpdateExceptionSuccess = (data) => {
  return {
    type: MASS_EXCEPTION_UPDATE,
    data,
  };
};
export const massUpdateExceptionAction = (data) => {
  return (dispatch) => {
    massUpdateExceptionActionAPI(data)
      .then((response) => {
        dispatch(massUpdateExceptionActionSuccess(response.data.response));
        history.push("/mass-exception-update-result");
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error("Something went wrong.");
      });
  };
};

const massUpdateExceptionActionAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("exceptions/mass-update/action", data, {
    headers: headers,
  });
  return result;
};

export const massUpdateExceptionActionSuccess = (data) => {
  return {
    type: MASS_EXCEPTION_UPDATE_ACTION,
    data,
  };
};
