import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  FETCH_START,
  FETCH_END,
  RESET_PASSWORD,
} from "./ActionTypes";
import axios from "../../../../axios.conf";
import { history } from "../../../../components/Helpers";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";

export const loginSuccess = (userData) => {
  return {
    type: LOGIN_SUCCESS,
    userData,
  };
};

export const loginFailed = () => {
  return {
    type: LOGIN_FAILED,
  };
};

export const login = (data, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getLoginApi(data, type)
      .then((response) => {
        dispatch(fetchEnd());
        if (type === 'New Session') {
          history.push(window.location.pathname)
        } else {
          localStorage.setItem("loggedIn", true);
          // localStorage.setItem("userDetails", JSON.stringify(response.data.response));
          secureLocalStorage.setItem("dYtz", response.data.response); //userDetails
          dispatch(loginSuccess(response.data.response));
          dispatch(authStoreToken(response.data.response));
          history.push("/applauncher");
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(loginFailed());

      });
  };
};

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};

const getLoginApi = (user, type) => {
  let data = {
    email: user.email,
    password: user.password,
  };
  const result = axios.post("login", data);
  return result;
};

export const authStoreToken = (response) => {
  return (dispatch) => {
    secureLocalStorage.setItem("aTk", response.token); //accesstoken
    secureLocalStorage.setItem("rFtk", response.refreshToken); //refrshtoken
    localStorage.setItem("loggedIn", "true");
    // localStorage.setItem('accessToken', response.token);
    // localStorage.setItem('refreshToken', response.refreshToken);
  };
};

export const authClearStorage = () => {
  return (dispatch) => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refreshToken");
  };
};

//reset Password
export const resetPasswordOld = (password, token) => {
  return (dispatch) => {
    postResetPasswordApi(password, token)
      .then((response) => {
        toast.success("Password has been successfully changed.");
        history.push("/login");
      })
      .catch((error) => {
      });
  };
};

const postResetPasswordApi = (password, token) => {
  let data = {
    token: token,
    new_password: password,
  };
  const result = axios.post("password/reset", data);
  return result;
};

//forgot Password
export const forgotPassword = (email) => {
  return (dispatch) => {
    forgotPasswordApi(email)
      .then((response) => {
        toast.success("Reset password link have been sent to your email");
      })
      .catch((error) => {
      });
  };
};

const forgotPasswordApi = (email) => {
  let data = {
    email: email,
  };
  const result = axios.post("password/forgot", data);
  return result;
};

//organization reset password
export const resetPasswordOrganization = (password, token) => {
  return (dispatch) => {
    postResetPasswordOrganizationApi(password, token)
      .then((response) => {
        toast.success("Password has been successfully changed.");
        history.push("/login");
      })
      .catch((error) => {
      });
  };
};

const postResetPasswordOrganizationApi = (password, token) => {
  let data = {
    token: token,
    new_password: password,
    verify_email: true,
  };
  const result = axios.post("password/reset", data);
  return result;
};

//reset Password
export const resetPassword = (op, np) => {
  return (dispatch) => {
    resetPasswordAPI(op, np)
      .then((response) => {
        localStorage.setItem("loggedIn", false);

        localStorage.clear();
        secureLocalStorage.clear();
        history.push("/login");
        toast.success("Password has been reset");
      })
      .catch((error) => {
      });
  };
};

const resetPasswordAPI = (op, np) => {
  let data = {
    old_password: op,
    new_password: np,
  };
  const result = axios.post("reset", data);
  return result;
};

export const resetPasswordSuccess = () => {
  return {
    type: LOGIN_FAILED,
  };
};
export const Oktalogin = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getOktaLoginApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        // console.log(response)
        if (response.data.error_description) {
          toast.error(response.data.error_description)
        }
        // if (type === 'New Session') {
        //   history.push(window.location.pathname)
        // } else {
        localStorage.setItem("loggedIn", true);
        // localStorage.setItem("userDetails", JSON.stringify(response.data.response));
        secureLocalStorage.setItem('user_type', response.data.response.user_type)
        secureLocalStorage.setItem("dYtz", response.data.response); //userDetails
        dispatch(loginSuccess(response.data.response));
        dispatch(authStoreToken(response.data.response));
        history.push("/applauncher");
        // }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //   if (
        //     error &&
        //     error.response &&
        //     error.response.data &&
        //     error.response.data.error &&
        //     error.response.data.error.message
        //   ) {
        //     //toast.error(error.response.data.error.message);
        //     dispatch(fetchEnd());
        //     dispatch(loginFailed());
        //   } else if (error.request) {
        //     dispatch(fetchEnd());
        //     //toast.error(error.request);
        //     dispatch(loginFailed());
        //   } else {
        //     dispatch(fetchEnd());
        //     //toast.error(error.message);
        //     dispatch(loginFailed());
        //   }
      }
      );
  };
};
const getOktaLoginApi = (data) => {
  const result = axios.post("okta/token", data);
  return result;
};