export const QUALIFIER_CONFIG_SUCCESS = "QUALIFIER_CONFIG_SUCCESS"
export const PRICE_LIST_FIELDS = "PRICE_LIST_FIELDS"
export const FETCH_START = "FETCH_START"
export const FETCH_END = "FETCH_END"
export const PRICE_TYPE_CONFIG_SUCCESS = "PRICE_TYPE_CONFIG_SUCCESS"
export const PRICE_TYPE_CONFIG_BY_ID = "PRICE_TYPE_CONFIG_BY_ID"
export const EXTERNAl_CONFIG_SUCCESS = "EXTERNAl_CONFIG_SUCCESS"
export const EXTERNAl_CONFIG_BY_ID = "EXTERNAl_CONFIG_BY_ID"
export const PRICE_LIST_MASTER_SUCCESS = "PRICE_LIST_MASTER_SUCCESS"
export const PRICE_LIST_MASTER_BY_ID = "PRICE_LIST_MASTER_BY_ID"
export const PRICE_QUALIFIER_NAME_SUCCESS = "PRICE_QUALIFIER_NAME_SUCCESS"
export const PRICE_TYPE_NAME_SUCCESS = "PRICE_TYPE_NAME_SUCCESS"
export const PRICE_CAL_FOR_LIST_SUCCESS = "PRICE_CAL_FOR_LIST_SUCCESS"
export const PRICE_CAL_FOR_LIST_BY_NAME = "PRICE_CAL_FOR_LIST_BY_NAME"
export const DRIVER_FORMAT_FIELDS = "DRIVER_FORMAT_FIELDS"
export const DRIVER_FORMAT_UPLOAD = "DRIVER_FORMAT_UPLOAD"
export const PROFIT_FORMAT_BY_ID = "PROFIT_FORMAT_BY_ID"
export const DRIVER_DATA_UPLOAD_FAILURE = "DRIVER_DATA_UPLOAD_FAILURE"
export const EXTERNAL_DATA_FORMAT_FIELDS_SUCCESS = "EXTERNAL_DATA_FORMAT_FIELDS_SUCCESS"
export const ALL_EXTERNAL_DATA = "ALL_EXTERNAL_DATA"
export const EXTERNAL_DATA_UPLOAD_SUCCESS = "EXTERNAL_DATA_UPLOAD_SUCCESS"
export const FLEXIBLE_ANALYSIS_SIMULATION_SUCCESS = "FLEXIBLE_ANALYSIS_SIMULATION_SUCCESS"
export const MARGIN_POL_EXC_SUCCESS = "MARGIN_POL_EXC_SUCCESS"
export const GROSS_TO_NET_SUCCESS = "GROSS_TO_NET_SUCCESS"
export const MARGIN_TREN_SUCCESS = "MARGIN_TREN_SUCCESS"
export const MEDIAN_MARGIN_SUCCESS="MEDIAN_MARGIN_SUCCESS"
