export const CALCULATION_SIMULATION_SUCCESS = "CALCULATION_SIMULATION_SUCCESS";
export const CALCULATION_SIMULATION_FAILED = "CALCULATION_SIMULATION_FAILED";
export const FETCH_START = "FETCH_START";
export const FETCH_END = "FETCH_END";
export const FINANCIAL_POSTINGS_SIMULATION_SUCCESS =
  "FINANCIAL_POSTINGS_SIMULATION_SUCCESS";
export const FINANCIAL_POSTINGS_SIMULATION_FAILED =
  "FINANCIAL_POSTINGS_SIMULATION_FAILED";
export const FINANCIAL_POSTINGS_SIMULATION_ERROR =
  "FINANCIAL_POSTINGS_SIMULATION_ERROR";
export const PAYMENT_POSTINGS_SIMULATION_SUCCESS =
  "PAYMENT_POSTINGS_SIMULATION_SUCCESS";
export const NEXT_TIER = "NEXT_TIER";
export const CALC_SIMULATION_SUCCESS = "CALC_SIMULATION_SUCCESS";
export const REBATE_ESTIMATE = "REBATE_ESTIMATE";
export const TLP_API_SUCCESS = "TLP_API_SUCCESS";
