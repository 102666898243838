import {
  KEY_FIGURE_ALL,
  KEY_FIGURE_SINGLE,
  FETCH_START,
  FETCH_END,
} from "./ActionTypes";
import axios from "../../../../axios.conf";
import { toast } from "react-toastify";
import { history } from "../../../../components/Helpers";
import secureLocalStorage from "react-secure-storage";

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};

export const addKeyFigureDefaultDesigner = (data, edit, id) => {
  return (dispatch) => {
    addKeyFigureAPI(data, edit, id)
      .then((response) => {
        history.push("/key-figure-mapping");
        if (edit == "edit") {
          toast.success("Successfully Updated.");
        } else {
          toast.success("Successfully added.");
        }
      })
      .catch((error) => {});
  };
};

const addKeyFigureAPI = (data, edit, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (edit === "edit")
    result = axios.put("sales-data-map/" + id, data, { headers: headers });
  else result = axios.post("sales-data-map", data, { headers: headers });
  return result;
};

export const getKeyFigureData = (pagination, limit) => {
  return (dispatch) => {
    getKeyFigureAPI(pagination, limit)
      .then((response) => {
        //dispatch(getKeyFigureableDataComplete(response.data.response.total_record));
        dispatch(getKeyFigureSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};

const getKeyFigureAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("sales-data-map?page=" + pagination + "&&limit=" + limit, {
    headers: headers,
  });
  return result;
};

export const getKeyFigureSuccess = (data) => {
  return {
    type: KEY_FIGURE_ALL,
    data,
  };
};

export const getSingleKeyFigure = (id) => {
  return (dispatch) => {
    getSingleKeyFigureAPI(id)
      .then((response) => {
        dispatch(getKeyFigureSuccessSingle(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.complete_error_message);
      });
  };
};

const getSingleKeyFigureAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("sales-data-map/" + id, { headers: headers });
  return result;
};

export const getKeyFigureSuccessSingle = (data) => {
  return {
    type: KEY_FIGURE_SINGLE,
    data,
  };
};

export const deleteKeyFigure = (id) => {
  return (dispatch) => {
    deleteKeyFigureApi(id)
      .then((response) => {
        dispatch(getKeyFigureData(1, 0));
        toast.success("Successfully deleted.");
      })
      .catch((error) => {});
  };
};

const deleteKeyFigureApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.delete("sales-data-map/" + id, { headers: headers });
  return result;
};
export const uploadKeyFigurFile = (file) => {
  return (dispatch) => {
    uploadKeyFigurFileApi(file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getKeyFigureData(1, 0));
          toast.success(response.data.response.success_msg);
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadKeyFigurFileApi = (file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
    "Content-Type": "multipart/form-data;",
  };
  var result;
  result = axios.post("sales-data-map/upload", file, { headers: headers });
  return result;
};
