import {
    GET_AGGREGATION_LEVEL,
    FETCH_START,
    FETCH_END,
    CLAIM_CONFIGURATION_SUCCESS,
    BUDGET_CONFIGURATION_SUCCESS,
    CLAIM_ERROR_SUCCESS,
    GET_AGGREGATION_LEVEL_BY_ID,
    CLAIM_ERROR_SINGLE_SUCCESS
} from '../../actions/CalculationAnalysis/ActionTypes';

const initialState = {
    loading: false,
    aggregationLevelData: [],
    claimData: [],
    budgetData: [],
    claimError:[],
    claimErrorSingle:[],
    aggregationLevelSingleData:[],
};

const CalculationAnalysisReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_AGGREGATION_LEVEL:
            return {
                ...state,
                aggregationLevelData: action.data,

            };
        case FETCH_START:
            return {
                ...state,
                loading: true
            }
        case FETCH_END:
            return {
                ...state,
                loading: false
            }
        case CLAIM_CONFIGURATION_SUCCESS:
            return {
                ...state,
                claimData: action.data,
            }
        case BUDGET_CONFIGURATION_SUCCESS:
            return {
                ...state,
                budgetData: action.data,
            }
        case CLAIM_ERROR_SUCCESS:
            return {
                ...state,
                claimError:action.data,
            }
            case CLAIM_ERROR_SINGLE_SUCCESS:
            return {
                ...state,
                claimErrorSingle:action.data,
            }
        case GET_AGGREGATION_LEVEL_BY_ID:
            return {
                ...state,
                aggregationLevelSingleData: action.data,
            };
        default:
            return state;
    }
};

export default CalculationAnalysisReducer;