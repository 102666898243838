import {
    LOCKED_PAGE
} from '../../../actions/Auth/Lock/ActionTypes';

const initialState = {
    lockedData: []
};

const LockReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOCKED_PAGE:
            return {
                ...state,
                lockedData: action.data
            };
        default:
            return state;
    }
};

export default LockReducer;