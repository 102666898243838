import {
    UOM_SUCCESS,
    UOM_SINGLE_SUCCESS,
    FETCH_START,
    FETCH_END
} from '../../actions/UoM/ActionTypes';

const initialState = {
    loading: false,
    uomData: [],
    uomSingleData: []
};

const UoMReducer = (state = initialState, action) => {
    switch (action.type) {


        case FETCH_START:
            return {
                ...state,
                loading: true
            }
        case FETCH_END:
            return {
                ...state,
                loading: false
            }
        case UOM_SUCCESS:
            return {
                ...state,
                uomData: action.data,
            };
        case UOM_SINGLE_SUCCESS:
            return {
                ...state,
                uomSingleData: action.data,
            }
        default:
            return state;
    }
};

export default UoMReducer;