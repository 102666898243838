import {
    KEY_FIGURE_ALL,
    KEY_FIGURE_SINGLE,
    FETCH_START,
    FETCH_END
} from '../../../actions/CalculationDesigner/AddKeyFigure/ActionTypes';
const initialState = {
    keyFigureData:[],
    keyFigureDataSingle:[],
    loading:false,
}
const AddKeyFigureReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_START:
            return {
                loading: true
            };
        case FETCH_END:
            return {
                loading: false
            };
        case KEY_FIGURE_ALL:
            return {
                ...state,
                keyFigureData: action.data
            };
        case KEY_FIGURE_SINGLE:
            return {
                ...state,
                keyFigureDataSingle: action.data
            };
        default:
            return state;
        }

}
export default AddKeyFigureReducer;
