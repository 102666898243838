import {
  HELP_DESK_ALL,
  HELP_DESK_SINGLE,
  FETCH_START,
  FETCH_END,
} from "./ActionTypes";
import axios from "../../../../axios.conf";
import { toast } from "react-toastify";
import { ServerError } from "../../../../library/constants";
import { history } from "../../../../components/Helpers";
import secureLocalStorage from "react-secure-storage";

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};
export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};
export const addHelpDesk = (data, edit, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addHelpDeskAPI(data, edit, id, type)
      .then((response) => {
        dispatch(fetchEnd());
        if (edit === "delete") {
          dispatch(getHelpDeskData(1, 10)); // Refresh the list after deletion
          toast.success(response.data.response);
        } else {
          if (type === "cp") {
            history.push("/help-desk-cp");
          } else {
            history.push("/help-desk");
          }
          toast.success(response.data.response);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const addHelpDeskAPI = (data, edit, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (edit === "edit")
    result = axios.put("helpdesk-video/" + id, data, { headers: headers });
  else if (edit == "delete") {
    result = axios.delete("helpdesk-video/" + id, { headers: headers });
  } else result = axios.post("helpdesk-video", data, { headers: headers });
  return result;
};
export const getHelpDeskData = (pagination, limit) => {
  return (dispatch) => {
    getHelpDeskAPI(pagination, limit)
      .then((response) => {
        dispatch(getHelpDeskSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};
const getHelpDeskAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (pagination === "noLimit")
    result = axios.get("helpdesk-video", { headers: headers });
  else
    result = axios.get(
      "helpdesk-video?page=" + pagination + "&&limit=" + limit,
      { headers: headers }
    );
  return result;
};
export const getHelpDeskSuccess = (data) => {
  return {
    type: HELP_DESK_ALL,
    data,
  };
};
export const getSingleHelpDesk = (id) => {
  return (dispatch) => {
    getSingleHelpDeskAPI(id)
      .then((response) => {
        dispatch(getHelpDeskSuccessSingle(response.data.response));
      })
      .catch((error) => {
        //toast.error('Error!');
      });
  };
};
const getSingleHelpDeskAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("helpdesk-video/" + id, { headers: headers });
  return result;
};
export const getHelpDeskSuccessSingle = (data) => {
  return {
    type: HELP_DESK_SINGLE,
    data,
  };
};
