import {
  DOCUMENT_HISTORY,
  DOCUMENT_DATA,
  DOCUMENT_DATA_BY_ID,
  DOCUMENT_LEVEL_DATA,
  DOCUMENT_LEVEL_BY_ID,
  DISTINCT_DOCUMENT_LEVEL_DATA,
  DOCUMENT_STATUS,
  FETCH_START,
  FETCH_END,
  SEARCH_DOCUMENT_SUCESS,
} from "./ActionTypes";
import axios from "../../../axios.conf";
import { toast } from "react-toastify";
//import { ServerError } from '../../../../library/constants';
import { history } from "../../../components/Helpers";
import secureLocalStorage from "react-secure-storage";

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};
export const addDocumentData = (data, id, type) => {
  return (dispatch) => {
    //dispatch(fetchStart());
    addDocumentDataAPI(data, id, type)
      .then((response) => {
        dispatch(fetchDocumentData());
        history.push("/documents");
        toast.success(response.data.response)
        toast.success("Document " + response.data.response.contract.document_id + " Saved")
      })
      .catch((error) => { });
  };
};

const addDocumentDataAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (id) {
    if (type == 'delete')
      result = axios.delete("document/" + id, data, {
        headers: headers,
      });
    else
      result = axios.put("document/" + id, data, {
        headers: headers,
      });
  }
  else
    result = axios.post("document?application_type=" + sessionStorage.getItem('application'), data, { headers: headers });
  return result;
};

export const fetchDocumentData = (pagination, limit) => {
  return (dispatch) => {
    fetchDocumentDataAPI(pagination, limit)
      .then((response) => {
        dispatch(fetchDocumentDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const fetchDocumentDataAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("document-list?application_type=" + sessionStorage.getItem('application'), { headers: headers });
  return result;
};

export const fetchDocumentDataSuccess = (data) => {
  return {
    type: DOCUMENT_DATA,
    data,
  };
};

export const fetchSingleDocumentData = (id, status) => {
  return (dispatch) => {
    fetchSingleDocumentDataAPI(id)
      .then((response) => {
        dispatch(fetchSingleDocumentDataSuccess(response.data.response));
        if (status == 'new')
          history.push('/documents/update/' + id)
        else if (status == 'view')
          history.push('/documents/view/' + id)
        else
          history.push('/documents/submit-approval/' + id)

      })
      .catch((error) => { });
  };
};

const fetchSingleDocumentDataAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("document/" + id, { headers: headers });
  return result;
};

export const fetchSingleDocumentDataSuccess = (data) => {
  return {
    type: DOCUMENT_DATA_BY_ID,
    data,
  };
};
export const getSearchDocumentResult = (data, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getSearchDocumentResultApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        // dispatch(getSearchDocumentSuccess(response.data.response));
        if (response.data.response.length > 0) {
          dispatch(fetchDocumentDataSuccess(response.data.response))
          if (type === 'List') {
            history.push('/documents-search-result')
          }
          else {
            history.push({
              pathname: "/documents",
              state: "searchByDocuments",
            });
          }
        }
        else {
          toast.error("No records found");
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getSearchDocumentResultApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  if (data.filter_fields.hasOwnProperty('duration')) {
    var result = axios.post("master-data-search?table_name=document_header&application_type=" + sessionStorage.getItem('application') + "&status_duration=true", data, { headers: headers });
  }
  else {
    var result = axios.post("master-data-search?table_name=document_header", data, { headers: headers });
  }
  // var result = axios.get("document-list", { params: data }, { headers: headers });
  return result;
};

export const getSearchDocumentSuccess = (data) => {
  return {
    type: SEARCH_DOCUMENT_SUCESS,
    data,
  }
}

// doc level

export const addDocumentLevels = (data, type) => {
  return (dispatch) => {
    //dispatch(fetchStart());
    addDocumentLevelsAPI(data, type)
      .then((response) => {
        history.push("/document-workflow");
        toast.success(response.data.response)
        dispatch(fetchDistinctDocumentLevels());
      })
      .catch((error) => { });
  };
};

const addDocumentLevelsAPI = (data, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  // if (id){
  if (type == 'delete')
    result = axios.delete("approval-workflow/bulk?functionality=" + data.functionality +
      "&application_type=" + data.application_type +
      "&workflow_name=" + data.workflow_name,
      {
        headers: headers,
      });
  else if (type == 'update')
    result = axios.put("approval-workflow", data, {
      headers: headers,
    });
  // }
  else
    result = axios.post("approval-workflow", data, { headers: headers });
  return result;
};

export const fetchDocumentLevels = (data, type) => {
  // console.log(data)
  return (dispatch) => {
    fetchDocumentLevelsAPI(data)
      .then((response) => {
        dispatch(fetchDocumentLevelsSuccess(response.data.response));
        if (type == 'update')
          history.push("/document-workflow/update/" + data.id)
        if (type == 'view')
          history.push("/document-workflow/view/" + data.id)
      })
      .catch((error) => { });
  };
};

const fetchDocumentLevelsAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("approval-workflow?application_type=" + data.application_type +
    "&functionality=" + data.functionality +
    "&workflow_name=" + data.workflow_name,
    // "&primary_anchor_value=" + data.primary_anchor_value,
    { headers: headers });
  return result;
};

export const fetchDocumentLevelsSuccess = (data) => {
  return {
    type: DOCUMENT_LEVEL_DATA,
    data,
  };
};

export const fetchSingleDocumentLevels = (id, type) => {
  return (dispatch) => {
    fetchSingleDocumentLevelsAPI(id)
      .then((response) => {
        dispatch(fetchSingleDocumentLevelsSuccess(response.data.response));
        if (type == 'edit')
          history.push('/document-workflow/update/' + id)
        //  if(type=='view') 
        //  history.push('/documents/view/'+id)

      })
      .catch((error) => { });
  };
};

const fetchSingleDocumentLevelsAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("approval-workflow/" + id, { headers: headers });
  return result;
};

export const fetchSingleDocumentLevelsSuccess = (data) => {
  return {
    type: DOCUMENT_LEVEL_BY_ID,
    data,
  };
};

export const fetchDistinctDocumentLevels = () => {
  return (dispatch) => {
    fetchDistinctDocumentLevelsAPI()
      .then((response) => {
        dispatch(fetchDistinctDocumentLevelsSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const fetchDistinctDocumentLevelsAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("approval-workflow/distinct", { headers: headers });
  return result;
};

export const fetchDistinctDocumentLevelsSuccess = (data) => {
  return {
    type: DISTINCT_DOCUMENT_LEVEL_DATA,
    data,
  };
};

export const fetchDocumentStatus = (data, type) => {
  return (dispatch) => {
    fetchDocumentStatusAPI(data, type)
      .then((response) => {
        dispatch(fetchDocumentStatusSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const fetchDocumentStatusAPI = (data, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (type == 'SubmitForApproval') {
    result = axios.post("approval-workflow/status-desc?pending=true", data, { headers: headers });
  } else {
    result = axios.post("approval-workflow/status-desc", data, { headers: headers });
  }
  return result;
};

export const fetchDocumentStatusSuccess = (data) => {
  return {
    type: DOCUMENT_STATUS,
    data,
  };
};


export const fetchDocumentHistory = (data) => {
  return (dispatch) => {
    fetchDocumentHistoryAPI(data)
      .then((response) => {
        dispatch(fetchDocumentHistorySuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const fetchDocumentHistoryAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("history-data", data, { headers: headers });
  //result = axios.get("document/history?document_id=" + id, { headers: headers });
  return result;
};

export const fetchDocumentHistorySuccess = (data) => {
  return {
    type: DOCUMENT_HISTORY,
    data,
  };
};
